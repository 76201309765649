import React from "react"
import { Link, graphql } from "gatsby"
import styled from 'styled-components';
import { device } from '../utils/device';
import { Container, Layout, SEO, Grid, TextRow } from "../components"


const CardTitle = styled.h4`
  color: var(--color-text-primary);
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 0;
`;

const Card = styled.div`
  margin: auto;
  margin-bottom: 40px;
  color: var(--color-text-primary);

  @media ${device.laptop} {
    width: auto;
    margin-left: 20px;
  }

  &:hover {
    ${CardTitle} {
      text-decoration: underline;
    }
  }
`;


const CardDescription = styled.span`
  font-size: 18px;
  color: var(--color-text-secondary);
  display: block;
`;

const CardCompany = styled.span`
  color: var(--color-text-secondary);
  font-size: 16px;

  .company {
    font-weight: bold;
  }

  .divider {
    display: inline-flex;
    margin: 0 7px;
  }

  .period {
    font-size: 14px;
  }
`;

const CustomTextRow = styled(TextRow)`
  @media ${device.laptop} {
    grid-column: 3/span 8;
  }
`;

const ProjectItem = ({ url, name, period, resume, company }) => (
  <Link to={`/${url}`}>
    <Card>
      <CardTitle>
          {name}
        </CardTitle>
      <CardDescription dangerouslySetInnerHTML={{__html: resume}} />
      <CardCompany>
        <span className="company">{company}</span>
        <span className="divider">•</span>
        <span className="period">{period}</span>
      </CardCompany>
    </Card>
  </Link>
);

const Projects = ({ data }) => {
  const projects = data.allProjectsJson.nodes;
  return (
    <Layout hideProfile>
      <SEO title="Projects" />
      <Container>
        <h1>Projects</h1>
        <Grid>
          <CustomTextRow>
            { projects.map(({ url, name, company, period, resume }) => (
                <ProjectItem
                  key={url}
                  url={url}
                  name={name}
                  company={company}
                  period={period}
                  resume={resume}
                />
            ))}
          </CustomTextRow>
        </Grid>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query ProjectsQuery {
    allProjectsJson {
      nodes {
        url
        name
        period
        resume,
        company
      }
    }
  }
`;

export default Projects;
